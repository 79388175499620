* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fcfcfc;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 5px;
  /* Adjust the height as needed */
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #999999;
}

input[type="color"]::-moz-color-swatch {
  border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: inset 0 0 20px 20px transparent !important;
}


.dark {
  --autofill-color: rgba(255, 255, 255, 0.87); /* Smooth white for dark mode */
}

svg {
  color: var(--autofill-color);
}

.dark .MuiSelect-icon {
  color: rgba(255, 255, 255, 0.87); /* Smooth white color for dark mode */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--autofill-color);
  transition: background-color 5000s ease-in-out 0s;
}